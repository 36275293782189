<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<GmFullscreenMedia></GmFullscreenMedia>

	2019-06-08	init

-->

<template>
	<div class="GmFullscreenMedia" :class="elmClasses">
		<div class="GmFullscreenMedia__inner">
			<!--
			GmFullscreenMedia
			<pre>{{slides}}</pre>
			-->
			<VueperSlides class="GmFullscreenMedia__slider no-shadow"
				v-if="slides && slides.length"
				fade
				transitionSpeed="750"
				duration="5000"
				autoplay
				:infinite="true"
				:touchable="false"
				:pauseOnHover="false"
				:bullets="false"
				@ready="onSliderReady"
				@slide="onSlideBefore"
			>
				<template v-slot:arrow-left>
					prev
				</template>
				<template v-slot:arrow-right>
					next
				</template>

				<VueperSlide v-for="(slide, i) in slides" :key="i">
					<template v-slot:content>
						<template v-if="screenAlignment == 'landscape'">
							<MhImage class="GmFullscreenMedia__slideImage"
								:imageObject="slide.landscapeImage ? slide.landscapeImage : slide.portraitImage"
								:mode="'cover'"
								:savePixel="100"
								slot="slideContent"
								v-if="slide.type == 'image'"
							></MhImage>
							<MhVideo class="GmFullscreenMedia__slideVideo"
								:videoObject="slide.landscapeVideo ? slide.landscapeVideo : slide.portraitVideo"
								:autoplay="video.autoplay"
								slot="slideContent"
								v-if="slide.type == 'video'"
							></MhVideo>
						</template>
						<template v-if="screenAlignment == 'portrait'">
							<MhImage class="GmFullscreenMedia__slideImage"
								:imageObject="slide.portraitImage ? slide.portraitImage : slide.landscapeImage"
								:mode="'cover'"
								:savePixel="100"
								slot="slideContent"
							></MhImage>
							<MhVideo class="GmFullscreenMedia__slideVideo"
								:videoObject="slide.portraitVideo ? slide.portraitVideo : slide.landscapeVideo"
								:autoplay="video.autoplay"
								slot="slideContent"
								v-if="slide.type == 'video'"
							></MhVideo>
						</template>

						<div class="GmFullscreenMedia__slideText font font--sans text text--center color color--almostWhite"
							slot="slideContent">
							<div class="GmFullscreenMedia__textAboveProgressBar text text--center font font--serif" v-if="_.get(slide, 'bigText')" v-html="_.get(slide, 'bigText')"></div>
							<div class="progressBar">
								<div class="progressBar__progress" :style="{ width : progressBar.width + '%', transitionDuration : progressBar.transitionDuration + 'ms' }"></div>
							</div>
							<a v-if="slide.link" :href="slide.link" v-html="slide.text"></a>
							<div v-else v-html="slide.text"></div>
						</div>

						<div class="GmFullscreenMedia__slideDebug" slot="slideContent">
							<pre data-name="type">{{slide.type}}</pre>
							<pre data-name="link">{{slide.link}}</pre>
						</div>
					</template>
				</VueperSlide>
			</VueperSlides>
			<!--
			<div class="GmFullscreenMedia__dummyImage"></div>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import { EventBus } from '@/event-bus.js'
	//import DevInfos from '@/components/DevInfos.vue'
	import debounce from 'lodash/debounce'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhVideo from '@/components/MhVideo/MhVideo.vue'

	//import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	//import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'
	import { VueperSlides, VueperSlide } from 'vueperslides'
	import 'vueperslides/dist/vueperslides.css'

	export default {
		name: 'GmFullscreenMedia',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
			MhVideo,
		},
		mixins: [],
		props: {
			slides: {
				type: [Array, Boolean],
				default: false,
			},
		},
		data() {
			return {
				screenAlignment : undefined, // wird von setScreenAlignment gesetzt
				video : {
					autoplay : true,
				},
				progressBar : {
					transitionDuration : 5000,
					width : 0
				},
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {
			getElmRect( elm ){
				if( elm ){
					//const elmStylesBefore = elm.getAttribute('style')
					//elm.setAttribute('style', '')

					const rect = elm ? elm.getBoundingClientRect() : undefined
					const top = rect ? parseFloat( rect.top.toFixed(2) ) : undefined
					const left = rect ? parseFloat( rect.left.toFixed(2) ) : undefined
					const height = rect ? parseFloat( rect.height.toFixed(2) ) : undefined
					const width = rect ? parseFloat( rect.width.toFixed(2)  ) : undefined

					const dimensions = {
						//height : elm ? elm.offsetHeight : undefined,
						//width  : elm ? elm.offsetWidth  : undefined,
						top : top,
						left : left,
						height : height,
						width  : width,
					}

					//elm.setAttribute('style', elmStylesBefore)

					return dimensions
				}
			},
			setScreenAlignment( doLog = false ){
				const winWidth  = window.innerWidth
				const winHeight = window.innerHeight
				const alignment = winWidth > winHeight ? 'landscape' : 'portrait'

				this.screenAlignment = alignment

				// group groupCollapsed
				if( doLog ){
					console.groupCollapsed(this.$options.name + ' • setScreenAspectRatio()')
					console.log('winWidth:', winWidth)
					console.log('winHeight:', winHeight)
					console.log('alignment:', alignment)
					console.groupEnd()
				}
			},
			onSliderReady( e, doLog = false ){
				// group groupCollapsed
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onReady()')
					console.log('e:', e)
					console.groupEnd()
				}
				this.progressBar.width = 0
				this.progressBar.transitionDuration = 0

				setTimeout(()=>{
					this.progressBar.transitionDuration = 5000-500
				}, 50)
				setTimeout(()=>{
					this.progressBar.width = 100
				}, 100)
			},
			onSlideBefore( e, doLog = false ){
				// group groupCollapsed
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onSlideBefore()')
					console.log('e:', e)
					console.groupEnd()
				}
				this.progressBar.width = 0
				this.progressBar.transitionDuration = 0

				// wait a tick to get the next active slide
				// reset the video time to 0
				this.$nextTick(()=>{
					const activeSlideElm = this.$el.querySelector('.vueperslide--active')
					const activeVideoElm = activeSlideElm.querySelector('video')

					console.log('activeSlideElm:', activeSlideElm)
					console.log('activeVideoElm:', activeVideoElm)

					if( activeVideoElm ) activeVideoElm.currentTime = 0
				})

				setTimeout(()=>{
					this.progressBar.transitionDuration = 5000-100
				}, 50)
				setTimeout(()=>{
					this.progressBar.width = 100
				}, 100)
			},
			// after window resize we must set the offset again
			// for performance reasons we use the debounce fn
			onWindowResizeHandler : debounce( function(e){
				this.setScreenAlignment()
				//this.setElmOffset()
			}, 250 ),
		},
		created(){
			window.addEventListener('resize', this.onWindowResizeHandler);
		},
		mounted( doLog = false ){
			this.setScreenAlignment()
			//this.setElmOffset()

			this.$parent.includesGmFullscreenMedia = true

			// group groupCollapsed
			if( doLog ){
				console.groupCollapsed(this.$options.name + ' • mounted()')
				console.log('slides', this.slides);
				console.groupEnd()
			}
		},
		destroyed(){
			window.removeEventListener('resize', this.onWindowResizeHandler);
		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.GmFullscreenMedia {
		//background-color: fade( yellow, 20 );

		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		width: 100vw; width: calc( 100vw - var(--scrollbarWidth));
		display: flex; // needed to expand the inner
		overflow: hidden;
		//opacity: 0.35;

		&__inner {
			//background-color: fade( yellow, 20 );
			position: relative;
			flex-grow: 1;
		}

		@bullet__height  : 1rem;
		@bullet__padding : 0.25rem;
		@bullet__opacity : 0.35;
		@arrow__shadow   : 0px 0px 5px fade(black, 30);
		@bullet__shadow  : 0px 0px 10px fade(black, 30);

		.vueperslides {
			position: relative;
			height: 100%;
			overflow: hidden;

			&__inner {
				height: inherit;
			}
			&__parallax-wrapper {
				height: 100vh !important;
				height: -webkit-fill-available !important;
				height: inherit !important;

				padding-bottom: 0 !important;
			}
		}
		.vueperslides { // hiden bullets
			&__bullets {
				//background-color: fade( orange, 30 );

				position: absolute;
				left: 0; right: 0;
				bottom: 1.3rem;
				z-index: 2;
				//display: none;

				@media @mediaQuery__md {
					bottom: 1rem;
				}
				@media @mediaQuery__sm {
					bottom: 0.5rem;
					bottom: 1rem;
				}
			}
			&__bullet {
				//outline: 1px solid red;
				transition: opacity 0.2s ease;
				display: flex;
				align-items: stretch;

				padding: @bullet__padding;
				height: @bullet__height;
				width: @bullet__height;

				span {
					border-radius: 50%;
					font-size: 0;
					line-height: 0;
					flex-grow: 1;
					background-color: fade( @swatches[almostWhite], 35 );
					box-shadow: @bullet__shadow;
				}
			}
			&__bullet--active {
				opacity: 1;
				padding: @bullet__padding * 0.5;

				span {
					background-color: @swatches[almostWhite];
				}
			}
		}
		.vueperslides { // prev + next arrows
			&__arrow {
				//background-color: fade(red, 50);

				position: absolute;
				bottom: 0; width: 50%;
				transition: opacity 0.3s ease;
				transform: none; // overwrite default styles

				opacity: 0; // make it clickable but invisible

				//@media @mediaQuery__md { display: none; }
				//@media @mediaQuery__sm { display: none; }
			}
			&__arrow--prev {
				top: 0;
				left: 0;
			}
			&__arrow--next {
				//top: 5em; // damit der arrow den menü-toggle-button nicht überlagert
				top: 0;
				right: 0;
			}
			&:hover .vueperSlides__arrow {
				opacity: 1;
			}
		}
		.vueperslide {  // slide: content wrapper full span height and width
			//position: relative;

			&__content {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				background-color: fade( cyan, 30 );
				height: 100%;
				overflow: hidden;

				display: flex;
				justify-content: center;
				align-items: center;
				//font-size: 6rem;
			}
		}

		@progressBarColor : white;
		.progressBar {
			background-color: fade( @progressBarColor, 35 );
			height: 2px;
			display: flex;
			width: 150px;
			max-width: 20vw;
			margin: 0 auto;
			margin-bottom: 1em;

			&__progress {
				background-color: @progressBarColor;
				width: 25%;
				transition-timing-function: linear;
			}
		}

		&__slideImage {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		&__slideVideo {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;

			.MhVideo__inner {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				padding-bottom: 0 !important;
			}
			.MhVideo__videoElm {
				object-fit: cover;
			}
		}
		&__slideLink {
			display: none;
			background-color: fade( blue, 25 );
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		&__slideText {
			//background-color: fade( yellow, 35 );

			position: absolute;
			left: 0; right: 0; bottom: 0;
			padding: 1em;
			transform: translateZ(1px); // 2021-03-26  fixes bug on safari were text above videos appeared with delay
			font-size: 16px;
			line-height: 1.35em;

			&:empty { display: none; }

			a { color: inherit; text-decoration: none; }
			a:hover { text-decoration: underline; }

			@media @mediaQuery__md {
				font-size: 14px;
			}
			@media @mediaQuery__sm {
				font-size: 12px;
			}
		}
		&__textAboveProgressBar {
			//background-color: fade(red, 50);
			font-size: 2em;
			margin: 0 auto;
			//max-width: 750px;
			line-height: 1.25em;
			padding-left: 1em;
			padding-right: 1em;
			padding-bottom: 0.65em;
		}
		&__slideDebug {
			display: none;
			position: absolute;
			top: 0; left: 0;
			z-index: 2;
		}
	}

	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
